import React from "react"
import { FaFacebook, FaTwitterSquare, FaInstagram } from "react-icons/fa"

export default [
{
    icon: <FaFacebook />,
    url: "https://facebook.com/nabendu.biswas.77",
},
{
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/nabendu82",
},
{
    icon: <FaInstagram />,
    url: "https://www.instagram.com/nabendu82/",
},
]
