export default [
    {
        path: "/",
        text: "home",
    },
    {
        path: "/contact",
        text: "Contact",
    },
    {
        path: "/about",
        text: "about",
    }
]
